import React, { FC } from "react";

export const ThanksPage: FC = () => {
  return (
    <div className="container">
      <section className="copy">
        <p>THANK YOU FOR UPDATING YOUR DETAILS</p>

        <a className="button" href="https://www.georgjensen.com/en-au">
          Explore Georg Jensen Online
        </a>
      </section>
    </div>
  );
};
