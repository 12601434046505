import React, { FC } from "react";
import { Link } from "react-router-dom";

export const ErrorPage: FC = () => {
  return (
    <div className="container">
      <section className="copy">
        <p>
          There has been an error in updating your details, please try again
        </p>

        <Link className="button" to="/">
          Return to form
        </Link>
      </section>
    </div>
  );
};
