import React, { FC } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import hero from "../assets/hero.jpg";
import logo from "../assets/logo.png";
import { Form } from "./Form";
import { ErrorPage } from "./pages/Error";
import { ThanksPage } from "./pages/Thanks";

export const App: FC = () => {
  return (
    <div className="app">
      <header>
        <img src={logo} alt="Georg Jensen" />
      </header>
      <div className="hero">
        <img src={hero} alt="Hero" />
      </div>

      <Router>
        <Switch>
          <Route path="/thanks">
            <ThanksPage />
          </Route>
          <Route path="/error">
            <ErrorPage />
          </Route>
          <Route path="/">
            <Form />
          </Route>
        </Switch>
      </Router>
    </div>
  );
};
