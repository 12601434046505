import React, { FC } from "react";

export type CheckboxProps = {
  readonly name: string;
  readonly checked: boolean;
  readonly onChange: (value: boolean) => void;
};

export const Checkbox: FC<CheckboxProps> = ({ name, checked, onChange }) => {
  return (
    <>
      <input type="hidden" name={name} value={checked ? "Yes" : "No"} />
      <span
        className={"checkbox" + (checked ? " checked" : "")}
        onClick={(event) => {
          event.preventDefault();
          onChange(!checked);
        }}
      />
    </>
  );
};
